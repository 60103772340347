import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withProps } from 'recompose';
import { formatRoute } from 'react-router-named-routes';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import ROUTES from 'react/routes';
import { PRODUCT_VIEW } from 'config/constants';
import { REVIEW_SOURCES } from 'react/business/review/reviews.constants';
import globalMessages from 'config/global.messages';
import { productShape } from 'shapes/product';
import withDisplayWarning from 'react/business/review/form/base/actions/withDisplayWarning';

import Logo, { LOGO_MAP } from 'react/generic/logo/Logo';
import Button from 'react/generic/button/Button';
import ReviewFormFields from 'react/business/review/form/base/steps/form/Fields.presentation';
import ReviewFormChildrenContainer from 'react/business/review/form/base/steps/form/Form.children.container';
import EmptyContentWarning from 'react/business/review/form/base/actions/warning/emptyContent/EmptyContent';

import logoAdeo from 'style/imgs/branding/LogoADEO.png';
import ADEOGroup from 'style/imgs/branding/ADEOGroup.png';
import messages from './form.messages';
import classNames from './form.module.scss';

const ReviewFormChildren = compose(
  ReviewFormChildrenContainer,
)(ReviewFormFields);

const enhancer = compose(
  memo,

  withDisplayWarning,

  withProps({
    source: REVIEW_SOURCES.SNIPPET,
  }),

  withHandlers({
    onShowWarning: ({ onShowWarning }) => (event) => {
      event.preventDefault();
      return onShowWarning();
    },
  }),
);

const CreateReviewStandaloneStepForm = ({
  product,
  onSubmit,
  isSubmitting,
  isWarningDisplayed,
  showFeatureSuggestionsLink,
  onShowWarning,
  onHideWarning,
  source,
}) => (
  <div className={cn(classNames.container, classNames.form)}>
    <div className={classNames.content}>

      <div className={classNames.logosCenter}>
        <img src={logoAdeo} className={classNames.logoAdeo} alt="no logo provided" />
        <Logo className={classNames.logo} name={LOGO_MAP.colors} />
      </div>
      <h1 className={classNames.title}>
        <div className={classNames.giveFeedback}>
          <FormattedMessage {...messages.ADD_REVIEW_ON_TANGRAM} />
        </div>
        <div className={classNames.productName}>
          {product?.name}
        </div>
      </h1>

      { !!showFeatureSuggestionsLink
        && (
          <div className={classNames.featureSuggestionsLinkContainer}>
            <a
              href={formatRoute(ROUTES.PRODUCT.PRESENTATION, {
                productId: product?.id,
                view: PRODUCT_VIEW.SUGGESTIONS,
              })}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames.featureSuggestionsLink}
            >
              <FormattedMessage {...messages.GO_TO_FEATURE_SUGGESTIONS} />
            </a>
          </div>
        ) }

      <ReviewFormChildren
        productId={product?.id}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        source={source}
      >
        {({ form, canSubmit, isContentEmpty }) => (
          <>
            <div>
              {form}
            </div>
            <div className={classNames.buttonContainer}>
              <EmptyContentWarning
                isDisplayed={isContentEmpty && isWarningDisplayed}
                onHide={onHideWarning}
              >
                <Button
                  type="submit"
                  disabled={!canSubmit}
                  onClick={(
                    isContentEmpty && !isWarningDisplayed ?
                      onShowWarning
                      : onHideWarning
                  )}
                >
                  {isContentEmpty && isWarningDisplayed ?
                    <FormattedMessage {...globalMessages.CONFIRM} />
                    : <FormattedMessage {...globalMessages.SUBMIT} />}
                </Button>
              </EmptyContentWarning>
            </div>
          </>
        )}
      </ReviewFormChildren>
    </div>
    <img src={ADEOGroup} className={classNames.logoGroup} alt="no adeogroup provided" />
  </div>
);

CreateReviewStandaloneStepForm.displayName = 'CreateReviewStandaloneStepForm';

CreateReviewStandaloneStepForm.propTypes = {
  onSubmit: PropTypes.func,
  product: productShape,
  isSubmitting: PropTypes.bool,
  isWarningDisplayed: PropTypes.bool.isRequired,
  showFeatureSuggestionsLink: PropTypes.bool,
  onShowWarning: PropTypes.func.isRequired,
  onHideWarning: PropTypes.func.isRequired,
  source: PropTypes.oneOf(Object.values(REVIEW_SOURCES)).isRequired,
};

CreateReviewStandaloneStepForm.defaultProps = {
  onSubmit: noop,
  product: null,
  showFeatureSuggestionsLink: false,
  isSubmitting: false,
};

export default enhancer(CreateReviewStandaloneStepForm);
