import React from 'react';
import connect from 'react/hoc/connectProxy';
import {
  compose,
  lifecycle,
  branch,
  renderComponent,
  mapProps,
} from 'recompose';

import { getProduct } from 'redux/products/actions';
import { selectIsProductLoading, selectProductById } from 'redux/products/selectors';

import withFormSteps from 'react/generic/form/withFormSteps';
import withSubmitCreateReview from 'react/business/review/form/base/withSubmitCreateReview';
import CreateReviewStandaloneStepFormPresentation from 'react/business/review/form/standalone/create/steps/form/Form';
import CreateReviewStandaloneStepSuccess from 'react/business/review/form/standalone/create/steps/success/Success';
import CreateReviewStandaloneStepFailure from 'react/business/review/form/standalone/create/steps/failure/Failure';
import TangramLoader from 'react/business/tangramLoader/TangramLoader';

import classNames from './createReviewStandalone.module.scss';

const CreateReviewStandaloneStepForm = compose(
  withSubmitCreateReview,
)(CreateReviewStandaloneStepFormPresentation);

export default compose(
  connect(
    (state, props) => ({
      product: selectProductById(state, props.productId),
      isLoading: selectIsProductLoading(state, props.productId),
    }),
    { getProduct },
  ),

  lifecycle({
    /** Load product on mount. */
    componentDidMount() {
      this.props.getProduct(this.props.productId);
    },
  }),

  // If the product is loading, display tangram loader.
  branch(
    props => !!props.isLoading,
    renderComponent(() => <div className={classNames.loaderContainer}><TangramLoader /></div>),
  ),

  // Add steps management and loading/failure steps presentations.
  withFormSteps(
    ({ productId }) => <CreateReviewStandaloneStepSuccess productId={productId} />,
    ({ onGoToFormStep }) => <CreateReviewStandaloneStepFailure onClickTryAgain={onGoToFormStep} />,
  ),

  // Map step change callbacks to Form component props API.
  mapProps(({
    onGoToSuccessStep,
    onGoToFailureStep,
    ...props
  }) => ({
    onSubmitSuccess: onGoToSuccessStep,
    onSubmitFailure: onGoToFailureStep,
    ...props,
  })),
)(CreateReviewStandaloneStepForm);
