import { defineMessages } from 'react-intl';

export default defineMessages({
  ADD_REVIEW_ON_TANGRAM: {
    id: 'createReview.formSnippet.addReviewOnTangram',
    defaultMessage: 'Give your feedback on',
  },

  GO_TO_FEATURE_SUGGESTIONS: {
    id: 'createReview.formSnippet.goToFeatureSuggestions',
    defaultMessage: 'Have an interesting idea for this product? Click here to suggest a feature.',
  },
});
