import { defineMessages } from 'react-intl';

export default defineMessages({
  GO_TO_TANGRAM_BY_CLICKING_LINK: {
    id: 'createReview.success.goToTangramByClickingLink',
    defaultMessage: 'Your feedback is available on Tangram, check it out by clicking the link below.',
  },

  GO_TO_TANGRAM: { id: 'createReview.success.goToTangram', defaultMessage: 'Go to Tangram' },
});
