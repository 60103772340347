import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';

import connect from 'react/hoc/connectProxy';
import { selectProductById } from 'redux/products/selectors';

import omitProps from 'react/hoc/omitProps';
import withRouteParams from 'react/hoc/withRouteParams';
import withRouteQueryString from 'react/hoc/withRouteQueryString';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import CreateReviewStandalonePage from 'react/pages/createReviewStandalone/CreateReviewStandalone';

import messages from './create-review-standalone.messages';

const enhancer = compose(
  withRouter,
  withRouteParams(),
  withRouteQueryString(({
    showFeatureSuggestionsLink,
  }) => ({
    showFeatureSuggestionsLink: showFeatureSuggestionsLink === 'true',
  })),

  omitProps([
    'match',
    'location',
    'history',
  ]),

  connect(
    (state, props) => ({
      productName: selectProductById(state, props.productId)?.name,
    }),
  ),

  memo,
);

const CreateReviewStandaloneView = ({ productId, productName, ...props }) => (
  <>
    <FormattedMessageChildren {...messages.TITLE} values={{ productName }}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>
    <CreateReviewStandalonePage productId={productId} {...props} />
  </>
);

CreateReviewStandaloneView.displayName = 'CreateReviewStandaloneView';

CreateReviewStandaloneView.propTypes = {
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string,
};

CreateReviewStandaloneView.defaultProps = {
  productName: null,
};

export default enhancer(CreateReviewStandaloneView);
