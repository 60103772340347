import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

/**
 * @param {Function} mapParamsToProps - Takes query parameters object, returns mapped props.
 * @returns {Function} HOC.
 */
const withRouteQueryString = mapParamsToProps => (WrappedComponent) => {
  const EnhancedComponent = ({ location, location: { search }, ...props }) => (
    <WrappedComponent {...props} {...mapParamsToProps(queryString.parse(search))} />
  );

  EnhancedComponent.propTypes = {
    /** Current location (href) data. */
    location: PropTypes.shape({
      /** Query string. */
      search: PropTypes.string,
    }).isRequired,
  };

  return EnhancedComponent;
};

export default withRouteQueryString;
