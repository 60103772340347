import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { formatRoute } from 'react-router-named-routes';

import { PRODUCT_VIEW } from 'config/constants';
import ROUTES from 'react/routes';

import { FormattedMessage } from 'react-intl';
import Button from 'react/generic/button/Button';
import ReviewFormSuccessStep from 'react/business/review/form/base/steps/success/Success';

import messages from './success.messages';

const enhancer = compose(
  memo,
);

const CreateReviewStandaloneStepSuccess = ({ productId }) => {
  const renderAction = useCallback(
    ({ className }) => (
      <a
        href={formatRoute(ROUTES.PRODUCT.PRESENTATION, {
          productId,
          view: PRODUCT_VIEW.REVIEWS,
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className={className}>
          <FormattedMessage {...messages.GO_TO_TANGRAM} />
        </Button>
      </a>
    ),
    [productId],
  );

  return (
    <ReviewFormSuccessStep
      successMessage={<FormattedMessage {...messages.GO_TO_TANGRAM_BY_CLICKING_LINK} />}
      renderAction={renderAction}
    />
  );
};

CreateReviewStandaloneStepSuccess.displayName = 'CreateReviewStandaloneStepSuccess';

CreateReviewStandaloneStepSuccess.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default enhancer(CreateReviewStandaloneStepSuccess);
